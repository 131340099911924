import axios from "axios";

const SHIPO_API_URL = process.env.REACT_APP_SHIPO_API_URL || "http://localhost:10010";
const TIMEOUT = process.env.API_TIMEOUT || 360000;

export default {
  getShipments: async (uid, shipment, guid)=>{
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
        url : "/getShipments",
        baseURL: requestURL,
        timeout: TIMEOUT,
        headers: {'x-api-key': 'p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2'}
        };
      try{
        const reply = await axios.post(requestURL + "/getShipments", {uid, shipment, guid}, axiosConfig);
        return reply.data;
      }
      catch(err){
        console.log(err);
        throw err;
      }
  },
  completeImportProposal: async (info)=>{
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
        url : "/completeImportProposal",
        baseURL: requestURL,
        timeout: TIMEOUT,
        headers: {'x-api-key': 'p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2'}
        };
    try{
      const reply = await axios.post(requestURL + "/completeImportProposal", info, axiosConfig);
      return reply.data;
    }
    catch(err){
      // handleErrors(err)
      console.log(err);
    }
  },
  getCities: async (country_id, city)=>{
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
        url : "/getCities",
        baseURL: requestURL,
        timeout: TIMEOUT,
        headers: {'x-api-key': 'p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2'}
        };
    try{
      const reply = await axios.post(requestURL + "/getCities", {country_id, name: city}, axiosConfig);
      let citiesList = reply.data.map((city)=>{
        return {
          "place name": city.label,
          "place name": city.label      
        }
      });

      return citiesList;
    }
    catch(err){
      // handleErrors(err)
      console.log(err);
    }
  },
  getCurrencies: async ()=>{
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
        url : "/currencies",
        baseURL: requestURL,
        timeout: TIMEOUT,
        headers: {
          'x-api-key': 'p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2',
          'Content-Type': 'application/json'
        }
        };
      try{
        const reply = await axios.post(requestURL + "/currencies", null, axiosConfig);
        return reply.data;
      }
      catch(err){
        console.log(err);
        throw err;
      }
  },
}