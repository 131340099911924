import * as redux from "redux"
import thunk from "redux-thunk"
import LogRocket from 'logrocket';

import proposalDetailsReducer from "./shipmentReducer"
import citiesReducer from "./citiesReducer";
import labelReducer from "./labelReducer";
import currenciesReducer from "./currenciesReducer"

// Be sure to ONLY add this middleware in development!
let middleware = process.env.NODE_ENV !== 'production' ?
[require('redux-immutable-state-invariant').default(), thunk] :
[thunk];
middleware = [thunk];

export let configure = (initialState={})=>{
    let reducer = redux.combineReducers({
    proposalDetails: proposalDetailsReducer,
    cities: citiesReducer,
    label: labelReducer,
    currencies: currenciesReducer
})

 let store = redux.createStore(reducer, initialState, redux.compose(
     redux.applyMiddleware(...middleware, LogRocket.reduxMiddleware()),
    window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION__():
    f=>f
));

    return store
}
