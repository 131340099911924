import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles'
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import MenuItem from '@mui/material/MenuItem';
import { spacing } from '@mui/system';
import ReactSelectMaterialUi from "react-select-material-ui";

import * as REDUCER from "../reducers/shipmentReducer";
import {
  getCitiesByZip,
  getCitiesByTypedName
} from "../reducers/citiesReducer";

const CA_States = [
  {value:'AB',label:'Alberta'},
  {value:'BC',label:'British Columbia'},
  {value:'MB',label:'Manitoba'},
  {value:'NB',label:'New Brunswick'},
  {value:'NL',label:'Newfoundland'},
  {value:'NT',label:'Northwest Territories'},
  {value:'NS',label:'Nova Scotia'},
  {value:'NU',label:'Nunavut'},
  {value:'ON',label:'Ontario'},
  {value:'PE',label:'Prince Edward Island'},
  {value:'QC',label:'Quebec'},
  {value:'SK',label:'Saskatchewan'},
  {value:'YT',label:'Yukon'},
  ]

const US_States = [
  {value:'AL',label:'Alabama'},
  {value:'AK',label:'Alaska'},
  {value:'AZ',label:'Arizona'},
  {value:'AR',label:'Arkansas'},
  {value:'CA',label:'California'},
  {value:'CO',label:'Colorado'},
  {value:'CT',label:'Connecticut'},
  {value:'DE',label:'Delaware'},
  {value:'DC',label:'District of Columbia'},
  {value:'FL',label:'Florida'},
  {value:'GA',label:'Georgia'},
  {value:'HI',label:'Hawaii'},
  {value:'ID',label:'Idaho'},
  {value:'IL',label:'Illinois'},
  {value:'IN',label:'Indiana'},
  {value:'IA',label:'Iowa'},
  {value:'KS',label:'Kansas'},
  {value:'KY',label:'Kentucky'},
  {value:'LA',label:'Louisiana'},
  {value:'ME',label:'Maine'},
  {value:'MD',label:'Maryland'},
  {value:'MA',label:'Massachusetts'},
  {value:'MI',label:'Michigan'},
  {value:'MN',label:'Minnesota'},
  {value:'MS',label:'Mississippi'},
  {value:'MO',label:'Missouri'},
  {value:'MT',label:'Montana'},
  {value:'NE',label:'Nebraska'},
  {value:'NV',label:'Nevada'},
  {value:'NH',label:'New Hampshire'},
  {value:'NJ',label:'New Jersey'},
  {value:'NM',label:'New Mexico'},
  {value:'NY',label:'New York'},
  {value:'NC',label:'North Carolina'},
  {value:'ND',label:'North Dakota'},
  {value:'OH',label:'Ohio'},
  {value:'OK',label:'Oklahoma'},
  {value:'OR',label:'Oregon'},
  {value:'PA',label:'Pennsylvania'},
  {value:'PR',label:'Puerto Rico'},
  {value:'RI',label:'Rhode Island'},
  {value:'SC',label:'South Carolina'},
  {value:'SD',label:'South Dakota'},
  {value:'TN',label:'Tennessee'},
  {value:'TX',label:'Texas'},
  {value:'UT',label:'Utah'},
  {value:'VA',label:'Virginia'},
  {value:'VT',label:'Vermont'},
  {value:'WA',label:'Washington'},
  {value:'WI',label:'Wisconsin'},
  {value:'WV',label:'West Virginia'},
  {value:'WY',label:'Wyoming'},  
]
// const useStyles = makeStyles(theme => ({
//   textField: {
//     marginLeft: spacing(1),
//     marginRight: spacing(1),
//     width: 200
//   }
// }));

export default () => {
  const [typedCityName, setTypedCityName] = React.useState("");
  const classes = useTheme();
  const proposalState = useSelector(state => state.proposalDetails);
  const citiesState = useSelector(state => state.cities);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getCitiesByZip(
        proposalState[0] != null
          ? proposalState[0].source_country.short_format
          : "",
        proposalState[0] != null
          ? proposalState[0].source_address.address.zip
          : ""
      )
    );
  }, []);
  useEffect(() => {
    dispatch(
      getCitiesByTypedName(
        proposalState[0] != null
          ? proposalState[0].source_country.short_format
          : "",
        typedCityName
      )
    );
  }, []);

  const handleCityChange = value => {
    dispatch(REDUCER.setSourceCity(value));
  };
  const checkEnteredCityName = value => {
    if (value.length > 3)
      dispatch(
        getCitiesByTypedName(
          proposalState[0] != null
            ? proposalState[0].source_country.short_format
            : "",
          value
        )
      );
  };

  let options = citiesState.cities.map(city => ({
    label: city["place name"],
    value: city["place name"]
  }));
  let existingValue = citiesState.cities.filter(city => {
    return city["place name"] == proposalState[0].source_address.address.city2;
  });
  if (!existingValue.length)
    options.push({
      label: proposalState[0].source_address.address.city2,
      value: proposalState[0].source_address.address.city2
    });

  if (!proposalState[0]) return "";
  const onSelectCity = zip => {
    dispatch(REDUCER.setSourceAddressZip(zip));
  };
  return (
    <Container>
      <TextField
        disabled
        id="country"
        label="Country"
        className={classes.textField}
        value={proposalState[0].source_country.label}
        sx={{m: 1}} 
        variant="standard" 
      />
      <form noValidate autoComplete="off">
        {proposalState[0].source_country.use_zipcode > 0 && (
          <TextField
            required
            id="zip"
            label="Zip"
            className={classes.textField}
            value={proposalState[0].source_address.address.zip}
            onChange={e => onSelectCity(e.target.value)}
            onBlur={e =>
              dispatch(
                getCitiesByZip(
                  proposalState[0].source_country.short_format,
                  e.target.value
                )
              )
            }
            sx={{m: 1}} 
            variant="standard" 
            />
        )}
        {/* {proposalState[0].source_country.has_states > 0 && (
          <TextField
            id="state"
            label="State"
            className={classes.textField}
            value={proposalState[0].source_address.address.state}
            onChange={e =>
              dispatch(REDUCER.setSourceAddressState(e.target.value))
            }
            margin="normal"
          />
        )} */}
        {proposalState[0].source_country.has_states > 0 && proposalState[0].source_country.short_format == "US" &&(
        <TextField
        required
        select 
        id="state"
        label="State"
        className={classes.textField}
        value={proposalState[0].source_address.address.state}
        onChange={(e)=>dispatch(REDUCER.setSourceAddressState(e.target.value))}
        sx={{m: 1}} 
        variant="standard" 
      >
      {US_States.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
      </TextField>)}
      {proposalState[0].source_country.has_states > 0 && proposalState[0].source_country.short_format == "CA" &&(
        <TextField
        required
        select 
        id="state"
        label="Province"
        className={classes.textField}
        value={proposalState[0].source_address.address.state}
        onChange={(e)=>dispatch(REDUCER.setSourceAddressState(e.target.value))}
        sx={{m: 1}} 
        variant="standard" 
      >
      {CA_States.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
      </TextField>)}

        <TextField
          required
          id="address"
          label="Address"
          className={classes.textField}
          value={proposalState[0].source_address.address.address2}
          onChange={e => dispatch(REDUCER.setSourceAddress(e.target.value))}
          sx={{m: 1}} 
          variant="standard" 
          helperText={`${proposalState[0].source_address.address.address2.length}/35`}
          inputProps={{ maxLength: 35}}
          />
        <TextField
          id="address1"
          label="Address1"
          className={classes.textField}
          value={proposalState[0].source_address.address.address2_line2}
          onChange={e => dispatch(REDUCER.setSourceAddress2(e.target.value))}
          helperText={`optional (${proposalState[0].source_address.address.address2_line2.length}/35)`}
          sx={{m: 1}} 
          variant="standard" 
          inputProps={{ maxLength: 35 }}
        />
        <TextField
          id="address2"
          label="Address2"
          className={classes.textField}
          value={proposalState[0].source_address.address.address2_line3}
          onChange={e => dispatch(REDUCER.setSourceAddress3(e.target.value))}
          helperText={`optional (${proposalState[0].source_address.address.address2_line3.length}/35)`}
          sx={{m: 1}} 
          variant="standard" 
          inputProps={{ maxLength: 35 }}
        />
        <ReactSelectMaterialUi
          fullWidth={true}
          label="City"
          SelectProps={{
            isCreatable: true,
            isValidNewOption: inputValue => inputValue !== "",
            onInputChange: value => checkEnteredCityName(value)
          }}
          style={{ width: 220, marginTop: "10px" }}
          value={proposalState[0].source_address.address.city2}
          options={options}
          onChange={value => handleCityChange(value)}
        />
        <TextField
          required
          id="company"
          label="Company Name"
          className={classes.textField}
          value={proposalState[0].source_address.address.name}
          onChange={e => dispatch(REDUCER.setSourceCompany(e.target.value))}
          helperText="Identical to the one on the invoice"
          sx={{m: 1}} 
          variant="standard" 
          />
        <TextField
          required
          id="contact"
          label="Contact Name"
          className={classes.textField}
          value={proposalState[0].source_address.contact.name}
          onChange={e => dispatch(REDUCER.setSourceContactName(e.target.value))}
          sx={{m: 1}} 
          variant="standard" 
          />
        <TextField
          required
          id="phone"
          label="Contact Phone"
          className={classes.textField}
          value={proposalState[0].source_address.contact.phone}
          onChange={e =>
            dispatch(REDUCER.setSourceContactPhone(e.target.value))
          }
          sx={{m: 1}} 
          variant="standard" 
          />
        <TextField
          required
          id="email"
          label="Contact email"
          className={classes.textField}
          value={proposalState[0].source_address.contact.email}
          onChange={e =>
            dispatch(REDUCER.setSourceContactEmail(e.target.value))
          }
          sx={{m: 1}} 
          variant="standard" 
          />
      </form>
    </Container>
  );
};
