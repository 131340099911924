import axios from "axios";
import API from "../api/proposalapi";
const ZIPPOPOTAM = "https://api.zippopotam.us/";

export const getCitiesByZip = (country, zipcode) => {
  return async (dispatch, getState) => {
    try {
      let axiosConfig = {
        url: "",
        baseURL: "",
        timeout: 30000
      };
      let reply = await axios.get(
        ZIPPOPOTAM + `${country}/${zipcode}`,
        axiosConfig
      );
      dispatch({ type: "CITIES", cities: reply.data.places });
    } catch (err) {
      dispatch({ type: "CITIES", cities: [] });
    }
  };
};
export const getCitiesByTypedName = (country_id, name) => {
  return async (dispatch, getState) => {
    try {
      let axiosConfig = {
        url: "",
        baseURL: "",
        timeout: 30000
      };
      let reply = await API.getCities(country_id, name);
      dispatch({ type: "CITIES", cities: reply });
    } catch (err) {
      dispatch({ type: "CITIES", cities: [] });
    }
  };
};
export default (state = { cities: [] }, action) => {
  switch (action.type) {
    case "CITIES":
      return {
        ...state,
        cities: action.cities
      };
    default:
      return state;
  }
};
