import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable'
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import LogRocket from "logrocket";

if (process.env.NODE_ENV !== "production") LogRocket.init("tvozxj/devportal");
else {
  LogRocket.init("tvozxj/my-app");
  if (!window.console) window.console = {};
  const methods = ["log", "debug", "warn", "info"];
  for (let i = 0; i < methods.length; i++) {
    console[methods[i]] = function() {};
  }
}

const store = require("./reducers/store").configure();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
