import React from "react";
import { useSelector } from "react-redux"
import { createTheme, ThemeProvider, styled,  useTheme } from '@mui/material/styles';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from "@mui/material/Container";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";

import Instructions from "./components/Instructions";
import Customs from "./components/Customs";
import PickupAddress from "./components/PickupAddress";
import PickupSchedule from "./components/PickupSchedule";
import Confirmation from "./components/Confirmation";
import { spacing } from '@mui/system';

// const theme = createTheme();
const theme = createTheme({
  root: {
    marginTop: "10%",
    direction: "ltr",
    borderColor: "black",
    borderWidth: "thick",
  },
  components: {
    paddingTop: spacing(1),
  },
  buttons_item: {
    margin: "1em"
  },
  buttons_container: {
    position: "fixed",
    bottom: "30%",
  },
  progress: {
    margin: spacing(2),
    marginTop: "20%",
    marginLeft: "50%",
  },
  successIcon: {
    color: 'green',
  },
  successText:{
    fontSize: "2rem",
  },
  textField: {
    marginLeft: spacing(1),
    marginRight: spacing(1),
    width: 200,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  currencyField: {
    marginLeft: spacing(1),
    marginRight: spacing(1),
    width: 40
  },
  formControl: {
    margin: spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: spacing(2),
  },
});

// const useStyles = makeStyles((theme) => ({
//   root: {
//     marginTop: "5%",
//     direction: "ltr",
//     borderColor: "black",
//     borderWidth: "thick",
//   },
//   components: {
//     paddingTop: theme.spacing(1),
//   },
//   buttons_item: {
//     margin: "1em"
//   },
//   buttons_container: {
//     position: "fixed",
//     bottom: "30%",
//   },
// }));

const validateCustoms = state=>{
  let errors = [];
  if( state.customs.value <= 0 && state.customs.content_type !== "DOCUMENTS")
    errors.push("The merchandise value should be non-zero");
  if( state.customs.description.length === 0)
    errors.push("Please provide a general description for the content of the shipment");
  // if( state.customs.signer.length === 0)
  //   errors.push("Please enter your name for the Signer's name");

  return {allowNext: errors.length === 0, errors: errors};
}

const validatePickup = state=>{
  let errors = [];
  if( state.source_country.use_zipcode &&state.source_address.address.zip.length <= 0 )
    errors.push("Zip code is mandatory");
  if( state.source_address.address.address2.length <= 0 )
    errors.push("Address is mandatory");
  if( state.source_address.address.city2.length <= 0 )
    errors.push("City is mandatory");
  if( state.source_address.address.name.length <= 0 )
    errors.push("Company name is mandatory");
  if( state.source_address.contact.name.length <= 0 )
    errors.push("Contact name is mandatory");
  if( state.source_address.contact.phone.length <= 0 )
    errors.push("Contact phone is mandatory");
  if( state.source_address.contact.email.length <= 0 )
    errors.push("Contact email is mandatory");

  let emailEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if( !emailEx.test(String(state.source_address.contact.email.trim()).toLowerCase()))
    errors.push("Invalid email address format")

  return {allowNext: errors.length === 0, errors};
}

const validateSchedule = state=>{
  let errors = [];

  if( state.pickupSchedule.pickup_date_to.length == 0 || state.pickupSchedule.pickup_date_from.length == 0)
    errors.push("Please send a pickup schedule");
  else{
    let dateTo = parseISO(
      state.pickupSchedule.pickup_date_to,
      "yyyy-MM-dd HH:mm",
      new Date()
    );
    let dateFrom = parseISO(
      state.pickupSchedule.pickup_date_from,
      "yyyy-MM-dd HH:mm",
      new Date()
    );
    let currentDate = new Date(format(
      new Date(),
      "yyyy-MM-dd"
    ))

    if( dateTo < dateFrom)
      errors.push("The pickup time to should be after the time from")
    if( dateFrom.getHours()<9)
      errors.push("Pickup hour is too early")
    if( dateTo.getHours()>18)
      errors.push("Pickup hour is too late")
    if( dateFrom < currentDate)
      errors.push("Pickup date cannot be in the past")
  }

  return {allowNext: errors.length === 0, errors};
}

function getSteps() {
  return [
    {component: <Instructions/>, label: "Instructions"}, 
    {component: <Customs/>, label: "Customs details", validation: validateCustoms},
    {component: <PickupAddress/>, label: "Pickup address", validation: validatePickup},
    {component: <PickupSchedule/>, label: "Pickup schedule", validation: validateSchedule},
  ];
}

export default function App() {
  // const classes = useStyles();
  const classes = useTheme();
  const matches = useMediaQuery(classes.breakpoints.up('lg'));
  // const matches = useMediaQuery('(min-height:500px)');
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  const proposalState = useSelector(state => state.proposalDetails);
  const steps = getSteps();

  const onCloseAlert = ()=>{
    setOpen(false)
    setErrors([]);
  }
  const handleNext = () => {
    let status = {allowNext: true, errror: ""}
    if( steps[activeStep].validation){
      status = steps[activeStep].validation(proposalState[0]);
    }

    if( status.allowNext)
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    else{
      setOpen(true);
      setErrors(status.errors)
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  if(activeStep === steps.length) 
    return (
      <Confirmation/>
    )

  return (
    <ThemeProvider theme={theme}>
    <Container sx={{mt:4}}>
    <Container className={theme.root}>
      <Stepper activeStep={activeStep} className={classes.stepper} sx={{mb: 2}} alternativeLabel>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          labelProps.alternativeLabel = (
            <Typography variant="caption">{step.label}</Typography>
          );
          return (
            <Step key={step.label} {...stepProps} >
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div className={theme.components}>
      {activeStep < steps.length && steps[activeStep].component}
      </div>
      {/* <Grid container spacing={2} className={matches ? classes.buttons_container: {}}> */}
      <Grid container spacing={2} className={matches ? classes.buttons_container : classes.buttons_item}>
        <Grid item>
        <Button
          variant="contained"
          color="secondary"
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          Back
        </Button>
        </Grid>
        {proposalState[0] != null && <Grid item >
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleNext}
        >
          {activeStep === steps.length - 1 ? "Create Label" : "Next"}
        </Button>
        </Grid>}
      </Grid>
      <Dialog
        open={open}
        onClose={onCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Errors filling the form"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ul>
            {errors.map(error=>{
              return <li><Typography>{error}</Typography></li>
            })}
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseAlert} color="primary">
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>      
      <Typography> </Typography>
      <Typography> </Typography>
      <Typography> </Typography>
      <Typography> </Typography>
    </Container>
    </Container>
    </ThemeProvider>
  );
}
