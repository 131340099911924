import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux"
// import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles'
import DateFnsUtils from '@date-io/date-fns';
import TextField from "@mui/material/TextField";
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'

import Container from "@mui/material/Container";
import {
  LocalizationProvider ,
  MobileTimePicker as TimePicker,
  DatePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { spacing } from '@mui/system';
import * as REDUCER from "../reducers/shipmentReducer";

// const useStyles = makeStyles(theme => ({
//   textField: {
//     marginLeft: spacing(1),
//     marginRight: spacing(1),
//     width: 200
//   }
// }));

export default ()=> {
 // The first commit of Material-UI
  const classes = useTheme();
  const proposalState = useSelector(state => state.proposalDetails);
  const dispatch = useDispatch();

  let timeFrom = parseISO(proposalState[0].pickupSchedule.pickup_date_from, "yyyy-MM-dd HH:mm", new Date());
  let timeTo = parseISO(proposalState[0].pickupSchedule.pickup_date_to, "yyyy-MM-dd HH:mm", new Date());

  useEffect(()=>{
    dispatch(REDUCER.setPickupDateTime(timeFrom, timeTo))}, []);


  if( timeFrom.getMinutes()> 30) timeFrom.setMinutes(30);
  if( timeFrom.getMinutes()< 30) timeFrom.setMinutes(0);
  if( timeTo.getMinutes()> 30) timeTo.setMinutes(30);
  if( timeTo.getMinutes()< 30) timeTo.setMinutes(0);

const handleDateChange = date => {
   dispatch(REDUCER.setPickupDate(date));
 };
const handleTimeFromChange = date => {
  dispatch(REDUCER.setPickupTimeFrom(date));
};
const handleTimeToChange = date => {
  dispatch(REDUCER.setPickupTimeTo(date));
};

return (
   <Container>
      <LocalizationProvider utils={DateFnsUtils} dateAdapter={AdapterDateFns}>
        <DatePicker
          sx={{m: 1, border: 0, orderColor: 'error.main'}} 
          id="date-picker-dialog"
          label="Pickup goods on date"
          // disablePast={true}  
          format="MM/dd/yyyy"
          defaultValue={parseISO(proposalState[0].pickupSchedule.pickup_date_from, "yyyy-MM-dd HH:mm", new Date())}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <TimePicker
          sx={{m: 1, border: 0}} 
          id="time-picker_to"
          label="From"
          ampm={proposalState[0].source_country.short_format === "US" || proposalState[0].source_country.short_format === "CA" ? true: false} 
          value={timeFrom}
          onChange={handleTimeFromChange}
          KeyboardButtonProps={{
            'aria-label': 'change time from',
          }}
        />
        <TimePicker
          sx={{m: 1}} 
          id="time-picker_from"
          label="Until "
          ampm={proposalState[0].source_country.short_format === "US" || proposalState[0].source_country.short_format === "CA" ? true: false} 
          value={timeTo}
          onChange={handleTimeToChange}
          KeyboardButtonProps={{
            'aria-label': 'change time until',
          }}
        />
        <TextField
          id="instructions"
          label="Pickup Instructions"
          className={classes.textField}
          value={proposalState[0].pickupSchedule.instructions}
          onChange={e => dispatch(REDUCER.setPickupInstructions(e.target.value))}
          helperText={`optional (${proposalState[0].pickupSchedule.instructions.length}/35)`}
          sx={{m: 1}} 
          variant="standard" 
          inputProps={{ maxLength: 35 }}
        />
      </LocalizationProvider>
    </Container>
    )
}
