import API from "../api/proposalapi";

const defaultLabel = {
  isLoading: true,
  isError: false,
  errors: "",
}

export const generateLabel = (proposal, pickup_date, pickup_date_to)=>{
  console.log("generateLabel")
  return async (dispatch, getState)=>{
    try{
    const info = {uid: proposal.shipment.uid, proposal_id: proposal.proposal_id, ...proposal, 
      pickup_date, pickup_date_to, instructions: proposal.pickupScheduleInstruction}
    const reply = await API.completeImportProposal(info);
    dispatch(setLabelReady(reply.shipment))
    }
    catch(err){
      dispatch(setLabelError(err.message || err.error.message ));
    }
  }    
}
export const setLabelReady = shipment=>{
  return {
    type: "LABEL_READY",
    shipment
  }
}

export const setLabelError = data=>{
  return {
    type: "LABEL_ERROR",
    data
  }
}

export default (state = defaultLabel, action)=>{
  switch(action.type){
    case "LABEL_READY":{
      return {
        ...state,
        shipment: action.shipment,
        isLoading: false,
        isError: false,
        errors: "",
      }
    }
    case "LABEL_ERROR":{
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.data
      }
    }
    default: 
    return state;
  }
}
