import API from "../api/proposalapi";

const defaultCurrencies = {
  isLoading: true,
  isError: false,
  errors: "",
  currencies: []
}

export const getCurrencies = ()=>{
  return async (dispatch, getState)=>{
    try{
    const reply = await API.getCurrencies();
    dispatch(setCurrencies(reply))
    }
    catch(err){
      dispatch(setCurrenciesError(err.message || err.error.message ));
    }
  }    
}
export const setCurrencies = currencies=>{
  return {
    type: "SET_CURRENCIES",
    currencies
  }
}

export const setCurrenciesError = data=>{
  return {
    type: "CURRENCIES_ERROR",
    data
  }
}

export default (state = defaultCurrencies, action)=>{
  switch(action.type){
    case "SET_CURRENCIES":{
      return {
        ...state,
        currencies: action.currencies,
        isLoading: false,
        isError: false,
        errors: "",
      }
    }
    case "CURRENCIES_ERROR":{
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.data
      }
    }
    default: 
    return state;
  }
}
