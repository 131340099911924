import LogRocket from "logrocket";
import API from "../api/proposalapi";
import format from "date-fns/format";
import formatISO from "date-fns/formatISO";
import set from "date-fns/set";
import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import addHours from "date-fns/addHours";

export const defaultDetails = {
  source_address: {},
  source_country: { label: "", short_format: "", value: 0, use_zipcode : true, has_states: false},
  source_zip_code: "",
  target_address: {},
  target_country: { label: "", short_format: "", value: 0 },
  target_zip_code: "",
  target_address_in_error: {},
  pickup_address: {},
  pickupAddress_error: {},
  proposalErrors: [],
  additionalDetails: "",
  loading: false,
  carriersList: [],
  carriersErrors: {},
  proposal_id: -1,
  selectedCarrier: {},
  extraServices: [],
  rate_id: "",
  carriersLoading: false,
  price: 0,
  priceCurrency: "USD",
  packages: [],
  calculated_weight: 0,
  is_import: false,
  create_commercial_invoice: false,
  customsContentType: "MERCHANDISE",
  customsContentDescription: "",
  customsHsCode: "",
  customsNoDelivery: "RETURN",
  customsItems: [],
  customsNetWeight: 0,
  customsValue: 0,
  customsCurrency: "USD",
  customsSignerName: "",
  customsTermsOfSale: "DAP",
  customsAccount: "",
  customsPayerCountry: { label: "", short_format: "", value: 0 },
  customsOrigin: { label: "", short_format: "", value: 0 },
  customerContinerSize: "20/40",
  payerAccount: "",
  payerCountry: { label: "", short_format: "", value: 0 },
  insured_value: 0,
  insurance: 0,
  customsErrors: [],
  pickupSchedule: {
    pickup_date_from: formatISO(new Date(), "yyyy-MM-dd HH:mm"), 
    pickup_date_to: formatISO(addHours(new Date(), 3), "yyyy-MM-dd HH:mm"), 
    instructions: "" 
  },
  pickupScheduleDateFrom: formatISO(new Date(), "yyyy-MM-dd HH:mm"),
  pickupScheduleDateTo: formatISO(addHours(new Date(), 3), "yyyy-MM-dd HH:mm"),
  pickupScheduleInstruction: "",
  schedule_loading: false,
  shipment_type: "",
  container_size: "20",
  port_name: "",
  referenceNo: ""
};

export const getShipments = (uid, shipment = "", guid = "") => {
  return (dispatch, getState) => {
    return API.getShipments(uid, shipment, guid)
      .then(res => {
        dispatch(setShipments(res));
      })
      .catch(err => {
        dispatch(setTrackingErrors(err.message || err.error.message));
      });
  };
};

export const setShipments = reply => {
  let shipments = reply.map(item => {
    return { ...item, value: item.id, label: item.number };
  });
  return {
    type: "TRACKING_SHIPMENT",
    shipments
  };
};

export let setTrackingErrors = errors => ({
  type: "TRACKING_ERRORS",
  errors
});

export const setCustomsValue = value => ({
  type: "CUSTOMS_VALUE",
  value
});

export const setCustomsCurrency = currency => ({
  type: "CUSTOMS_CURRENCY",
  currency
});

export const setCustomsContentType = type => ({
  type: "CUSTOMS_CONTENT",
  content_type: type
});

export const setCustomsTermsOfSale = terms => ({
  type: "CUSTOMS_TERMS_OF_SALE",
  terms
});

export const setCustomsDescription = description => ({
  type: "CUSTOMS_DESCRIPTION",
  description
});

export const setCustomsNoDelivery = no_delivery => ({
  type: "CUSTOMS_NO_DELIVERY",
  no_delivery
});

export const setCustomsSigner = signer => ({
  type: "CUSTOMS_SIGNER",
  signer
});

export const setSourceAddressZip = zip => ({
  type: "SOURCE_ZIP",
  zip
});

export const setSourceAddressState = state => ({
  type: "SOURCE_STATE",
  state
});

export const setSourceAddress = address => ({
  type: "SOURCE_ADDRESS",
  address
});
export const setSourceAddress2 = address => ({
  type: "SOURCE_ADDRESS2",
  address
});

export const setSourceAddress3 = address => ({
  type: "SOURCE_ADDRESS3",
  address
});

export const setPickupInstructions = instructions => ({
  type: "PICKUP_INSTRUCTIONS",
  instructions
});

export const setSourceCity = city => ({
  type: "SOURCE_CITY",
  city
});

export const setSourceCompany = company => ({
  type: "SOURCE_COMPANY",
  company
});
export const setSourceContactName = name => ({
  type: "SOURCE_CONTACT_NAME",
  name
});
export const setSourceContactPhone = phone => ({
  type: "SOURCE_CONTACT_PHONE",
  phone
});
export const setSourceContactEmail = email => ({
  type: "SOURCE_CONTACT_EMAIL",
  email
});
export const setPickupDateTime = (date_from, date_to)=>({
  type: "PICKUP_DATETIME",
  date_from,
  date_to
})
export const setPickupDate = date => ({
  type: "PICKUP_DATE",
  date
});
export const setPickupTimeFrom = date => ({
  type: "PICKUP_TIME_FROM",
  date
});
export const setPickupTimeTo = date => ({
  type: "PICKUP_TIME_TO",
  date
});
export default (state = defaultDetails, action) => {
  switch (action.type) {
    case "TRACKING_SHIPMENT":
      LogRocket.identify(`${action.shipments[0].shipment.number}`, {
        email: action.shipments[0].source_address.contact.email
      });

      return {
        ...state,
        ...JSON.parse(JSON.stringify(action.shipments)),
        loading: false
      };
    case "CUSTOMS_VALUE": {
      let shipment = state[0];
      let customs = shipment.customs;
      customs = { ...customs, value: action.value };
      shipment = { ...shipment, customs: { ...customs } };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "CUSTOMS_CURRENCY": {
      let shipment = state[0];
      let customs = shipment.customs;
      customs = { ...customs, currency: action.currency };
      shipment = { ...shipment, customs: { ...customs } };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "CUSTOMS_CONTENT": {
      let shipment = state[0];
      let customs = shipment.customs;
      customs = { ...customs, content_type: action.content_type };
      shipment = { ...shipment, customs: { ...customs } };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "SOURCE_ZIP": {
      let shipment = state[0];
      let source_address = shipment.source_address;
      source_address.address.zip = action.zip;
      shipment = { ...shipment, source_address };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "SOURCE_STATE": {
      let shipment = state[0];
      let source_address = shipment.source_address;
      source_address.address.state = action.state;
      shipment = { ...shipment, source_address };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "SOURCE_ADDRESS": {
      let shipment = state[0];
      let source_address = shipment.source_address;
      source_address.address.address2 = action.address;
      shipment = { ...shipment, source_address };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "SOURCE_ADDRESS2": {
      let shipment = state[0];
      let source_address = shipment.source_address;
      source_address.address.address2_line2 = action.address;
      shipment = { ...shipment, source_address };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "SOURCE_ADDRESS3": {
      let shipment = state[0];
      let source_address = shipment.source_address;
      source_address.address.address2_line3 = action.address;
      shipment = { ...shipment, source_address };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "SOURCE_CITY": {
      let shipment = state[0];
      let source_address = shipment.source_address;
      source_address.address.city2 = action.city;
      shipment = { ...shipment, source_address };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "SOURCE_COMPANY": {
      let shipment = state[0];
      let source_address = shipment.source_address;
      source_address.address.name = action.company;
      shipment = { ...shipment, source_address };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "SOURCE_CONTACT_NAME": {
      let shipment = state[0];
      let source_address = shipment.source_address;
      source_address.contact.name = action.name;
      shipment = { ...shipment, source_address };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "SOURCE_CONTACT_PHONE": {
      let shipment = state[0];
      let source_address = shipment.source_address;
      source_address.contact.phone = action.phone;
      shipment = { ...shipment, source_address };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "SOURCE_CONTACT_EMAIL": {
      let shipment = state[0];
      let source_address = shipment.source_address;
      source_address.contact.email = action.email;
      shipment = { ...shipment, source_address };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "CUSTOMS_TERMS_OF_SALE": {
      let shipment = state[0];
      let customs = shipment.customs;
      customs = { ...customs, terms_of_sale: action.terms };
      shipment = { ...shipment, customs: { ...customs } };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "CUSTOMS_DESCRIPTION": {
      let shipment = state[0];
      let customs = shipment.customs;
      customs = { ...customs, description: action.description };
      shipment = { ...shipment, customs: { ...customs } };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "CUSTOMS_NO_DELIVERY": {
      let shipment = state[0];
      let customs = shipment.customs;
      customs = { ...customs, no_delivery: action.no_delivery };
      shipment = { ...shipment, customs: { ...customs } };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "CUSTOMS_SIGNER": {
      let shipment = state[0];
      let customs = shipment.customs;
      customs = { ...customs, signer: action.signer };
      shipment = { ...shipment, customs: { ...customs } };
      return {
        ...state,
        [0]: { ...shipment }
      };
    }
    case "PICKUP_DATETIME":{
      return {
        ...state,
        [0]: {...state[0],
        pickupSchedule: {
          ...state[0].pickupSchedule, 
          pickup_date_from: format(action.date_from, "yyyy-MM-dd HH:mm"), 
          pickup_date_to: format(action.date_to, "yyyy-MM-dd HH:mm")}
        },
        pickupScheduleDateFrom: format(action.date_from, "yyyy-MM-dd HH:mm"),
        pickupScheduleDateTo: format(action.date_to, "yyyy-MM-dd HH:mm")
      }
    }
    case "PICKUP_DATE": {
      let dateOnly = set(action.date, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0
      });
      console.log(dateOnly.getYear(), dateOnly.getMonth(), dateOnly.getDate());
      let dateFrom = new Date();
      let dateTo = new Date();
      if (state[0].pickupSchedule.pickup_date_from.length > 0)
        dateFrom = parseISO(
          state[0].pickupSchedule.pickup_date_from,
          "yyyy-MM-dd HH:mm",
          new Date()
        );
      if (state[0].pickupSchedule.pickup_date_to.length > 0)
        dateTo = parseISO(
          state[0].pickupSchedule.pickup_date_to,
          "yyyy-MM-dd HH:mm",
          new Date()
        );
      let newDateFrom = set(dateFrom, {
        year: 1900 + dateOnly.getYear(),
        month: dateOnly.getMonth(),
        date: dateOnly.getDate()
      });
      let newDateTo = set(dateTo, {
        year: 1900 + dateOnly.getYear(),
        month: dateOnly.getMonth(),
        date: dateOnly.getDate()
      });
      let shipment = { ...state[0], 
        pickupSchedule: { 
          ...state[0].pickupSchedule, 
          pickup_date_from: format(newDateFrom, "yyyy-MM-dd HH:mm"),
          pickup_date_to: format(newDateTo, "yyyy-MM-dd HH:mm") 
        } 
      };

      return {
        ...state,
        [0]: {...shipment},
        pickupScheduleDateFrom: format(newDateFrom, "yyyy-MM-dd HH:mm"),
        pickupScheduleDateTo: format(newDateTo, "yyyy-MM-dd HH:mm")
      };
    }
    case "PICKUP_TIME_FROM": {
      let timeOnly = set(action.date, {
        year: 0,
        month: 0,
        date: 0,
        seconds: 0,
        milliseconds: 0
      });
      let dateFrom = new Date();
      if (state[0].pickupSchedule.pickup_date_from.length > 0)
        dateFrom = parseISO(
          state[0].pickupSchedule.pickup_date_from,
          "yyyy-MM-dd HH:mm",
          new Date()
        );
      let newDateFrom = set(dateFrom, {
        hours: timeOnly.getHours(),
        minutes: timeOnly.getMinutes()
      });
      let shipment = { ...state[0], 
        pickupSchedule: { ...state[0].pickupSchedule, 
          pickup_date_from: format(newDateFrom, "yyyy-MM-dd HH:mm"),
        } 
      };
      return {
        ...state,
        [0]: {...shipment},
        pickupScheduleDateFrom: format(newDateFrom, "yyyy-MM-dd HH:mm")
      };
    }
    case "PICKUP_TIME_TO": {
      let timeOnly = set(action.date, {
        year: 0,
        month: 0,
        date: 0,
        seconds: 0,
        milliseconds: 0
      });
      let dateTo = new Date();
      if (state[0].pickupSchedule.pickup_date_to.length > 0)
        dateTo = parseISO(
          state[0].pickupSchedule.pickup_date_to,
          "yyyy-MM-dd HH:mm",
          new Date()
        );
      let newDateTo = set(dateTo, {
        hours: timeOnly.getHours(),
        minutes: timeOnly.getMinutes()
      });
      let shipment = { ...state[0], 
        pickupSchedule: { ...state[0].pickupSchedule, 
          pickup_date_to: format(newDateTo, "yyyy-MM-dd HH:mm")
        } 
      };
      return {
        ...state,
        [0]: {...shipment},
        pickupScheduleDateTo: format(newDateTo, "yyyy-MM-dd HH:mm")
      };
    }
    case "PICKUP_INSTRUCTIONS":{
      let shipment = {...state[0],
        pickupSchedule: {...state[0].pickupSchedule, 
          instructions: action.instructions
        },
      }
      return {
        ...state,
        [0]: { ...shipment},
        pickupScheduleInstruction:  action.instructions
        }
    }
    case "LABEL_READY":
      return {
        ...defaultDetails
      };
    case "TRACKING_ERRORS":
      return {
        ...state,
        proposalErrors: action.errors      
      }
    default:
      return state;
  }
};
