import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux"
// import { makeStyles, useTheme } from '@mui/styles';
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress';
import { Typography, Container } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import { spacing } from '@mui/system';

import {generateLabel} from "../reducers/labelReducer";

// const useStyles = makeStyles(theme => ({
//   progress: {
//     margin: spacing(2),
//     marginTop: "20%",
//     marginLeft: "50%",
//   },
//   successIcon: {
//     color: 'green',
//   },
//   successText:{
//     fontSize: "2rem",
//   }
// }));

export default () => {
  const [isLoading, setLoading] = useState(1);
  const proposalState = useSelector(state => state.proposalDetails);
  const labelState = useSelector(state => state.label);
  const dispatch = useDispatch();

  useEffect(()=>dispatch(generateLabel(proposalState[0], proposalState.pickupScheduleDateFrom, proposalState.pickupScheduleDateTo)), []);

  const classes = useTheme();
  console.log("labelState.loading", labelState.isLoading);
  if( isLoading !== labelState.isLoading) setLoading(labelState.isLoading);

  if( isLoading)
    return (
      <Container>
        <Typography>We are preparing the label. This process might take a few minutes. Please wait for the process to complete.</Typography>
        <CircularProgress className={classes.progress} />
      </Container>
    )
  if( labelState.isError)
    return (
      <Container>
        <Typography>{`There has been error: ${labelState.errors}`}</Typography>
      </Container>
    )
  const shipment = labelState.shipment;
  return (
    <Container>
        <Typography className={classes.successText}><DoneIcon/>Label was successfuly generated.</Typography>
        <Typography>The Packjet order number is:</Typography>
        <b>{shipment.number}</b>
        <Typography>Carrier fulfilling the pickup is:</Typography><b>{shipment.carrier_name}</b>
        <Typography>Delivery Address</Typography>
        <b>{`${shipment.target_address}, ${shipment.target_city}, ${shipment.target_zip}, ${shipment.target_country}`}</b>

        {shipment.tracking_code.length > 0 && 
        <>
        <Typography>Tracking code is: <b>{shipment.tracking_code}</b></Typography>
        </>
        }
        <Typography>An email with further instructions has been sent to:</Typography>
        <b>{shipment.source_email}</b>
        <div> 
          <img src={"https://s3-eu-west-1.amazonaws.com/packjet.images/img.png"} alt=""/>
        </div>
    </Container>
    );
}
